import React from "react";
import { Box, Flex, Button, Image, Link } from "@chakra-ui/react";
import logo from "../../assets/logos/logo.png";

const Navbar = ({ onGetStartedClick }) => {
  return (
    <Box as="nav" bg="white" boxShadow="0px 1px 3px rgba(0, 0, 0, 0.15)" py={4}>
      <Flex
        maxW="container.xl"
        mx="auto"
        px={4}
        align="center"
        justify="space-between"
      >
        <Image src={logo} alt="Logo" h="40px" />
        <Flex align="center">
          <Link href="https://app.coupal.io" mr={{ base: 1, md: 4 }}>
            <Button
              color="#2e2c2e"
              bg="transparent"
              _hover={{
                textDecoration: "underline",
                bg: "transparent",
              }}
              _active={{
                bg: "transparent",
              }}
            >
              Sign In
            </Button>
          </Link>
          <Button
            onClick={onGetStartedClick}
            color="white"
            bg="black"
            _hover={{
              bg: "#18181a",
            }}
          >
            Start Fundraising
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navbar;
