import React from "react";
import { Box, Container, Heading } from "@chakra-ui/react";
import Navbar from "../components/custom/Navbar";
import Footer from "../components/custom/Footer";
import ContactForm from "../components/custom/ContactForm";
import { Toaster } from "../components/ui/toaster";

const ContactPage = () => {
  return (
    <Box>
      <Navbar />
      <Container maxW="container.xl" py={16}>
        <Heading as="h1" size="2xl" fontWeight="bold" textAlign="center">
          Contact Us
        </Heading>
        <Box
          width="50px"
          height="3px"
          backgroundColor="black"
          margin="10px auto 20px"
        />
        <ContactForm />
      </Container>
      <Footer />
      <Toaster />
    </Box>
  );
};

export default ContactPage;
